import { createSlice } from "@reduxjs/toolkit";
import {
  getUserPermissions,getMiniCity,getMiniStates,getMiniLocation
} from "./miniActions";
const miniSlice = createSlice({
  name: "mini",
  initialState: {
    userAccessList: [],
    miniStatesList: [],
    miniStateLoading: false,
    miniCityList: [],
    miniCityLoading: false,
    miniUserList: [],
    miniAreaList: [],
    miniAreaLoading: false,
    miniLocationList: [],
    miniLocationLoading: false,
    miniStatesParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    miniCityParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    miniAreaParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    miniLocationParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    miniZoneParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    miniUserParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
  },
  reducers: {
    setMiniStatesParams: (state, action) => {
      return {
        ...state,
        miniStatesParams: action.payload,
      };
    },
    setMiniZoneParams: (state, action) => {
      return {
        ...state,
        miniZoneParams: action.payload,
      };
    },
    setMiniAreaParams: (state, action) => {
      return {
        ...state,
        miniAreaParams: action.payload,
      };
    },
    setMiniLocationParams: (state, action) => {
      return {
        ...state,
        miniLocationParams: action.payload,
      };
    },
    getMiniStateClearData: (state) => {
      return {
        ...state,
        miniStatesList: [],
      };
    },
    setMiniCityParams: (state, action) => {
      return {
        ...state,
        miniCityParams: action.payload,
      };
    },
    getMiniCityClearData: (state) => {
      return {
        ...state,
        miniCityList: [],
      };
    },
    setMiniUserParams: (state, action) => {
      return {
        ...state,
        miniUserParams: action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserPermissions.pending, (state, action) => {
        state.status = "getUserPermissions loading";
        state.loading = true;
      })
      .addCase(getUserPermissions.fulfilled, (state, action) => {
        state.status = "getUserPermissions succeeded";
        state.loading = false;
        state.userAccessList = action.payload.response;
      })
      .addCase(getUserPermissions.rejected, (state, action) => {
        state.status = "getUserPermissions failed";
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getMiniStates.pending, (state, action) => {
        state.status = "getMiniStates loading";
        state.miniStateLoading = true;
      })
      .addCase(getMiniStates.fulfilled, (state, action) => {
        state.status = "getMiniStates succeeded";
        var response = action.payload.response;
        var params = action.payload.params;
        var list = [];
        // if (params.search) {
        //   list = response.results;
        // } else {
        if (params?.page == 1) {
          list = response.results;
        } else {
          list = [...state.miniStatesList, ...response.results];
        }
        // }

        var noofpages = Math.ceil(
          response.count / state.miniStatesParams?.page_size
        );
        state.miniStatesList = list;
        state.miniStateLoading = false;
        state.miniStatesParams = {
          ...state.miniStatesParams,
          no_of_pages: noofpages,
        };
      })
      .addCase(getMiniStates.rejected, (state, action) => {
        state.status = "getMiniStates failed";
        state.miniStateLoading = false;
        state.error = action.error.message;
      })
      .addCase(getMiniCity.pending, (state, action) => {
        state.status = "getMiniCity loading";
        // if (state.miniCityParams?.page == 1) {
        //   state.miniCityLoading = true;
        //   state.miniCityList = [];
        // } else {
        state.miniCityLoading = true;
        // }
      })
      .addCase(getMiniCity.fulfilled, (state, action) => {
        state.status = "getMiniCity succeeded";
        var response = action.payload.response;
        var params = action.payload.params;
        var list = [];
        if (params?.page == 1) {
          list = response.results;
        } else {
          list = [...state.miniCityList, ...response.results];
        }

        var noofpages = Math.ceil(
          response.count / state.miniCityParams?.page_size
        );
        state.miniCityList = list;
        state.miniCityLoading = false;
        state.miniCityParams = {
          ...state.miniCityParams,
          no_of_pages: noofpages,
        };
      })
      .addCase(getMiniCity.rejected, (state, action) => {
        state.status = "getMiniCity failed";
        state.miniCityLoading = false;
        state.error = action.error.message;
      })
      .addCase(getMiniLocation.pending, (state, action) => {
        state.status = "getMiniLocation loading";
        // if (state.miniLocationParams?.page == 1) {
        //   state.miniLocationLoading = true;
        //   state.miniLocationList = [];
        // } else {
        state.miniLocationLoading = true;
        // }
      })
      .addCase(getMiniLocation.fulfilled, (state, action) => {
        state.status = "getMiniLocation succeeded";

        console.log(action.payload)
        var response = action.payload.response;
        var params = action.payload.params;
        var list = [];
        // if (params.search) {
        //   list = response.results;
        // } else
        if (params?.page == 1) {
          console.log(response.results)
          list = response.results;
        } else {
          list = [...state.miniLocationList, ...response.results];
        }
        var noofpages = Math.ceil(
          response.count / state.miniLocationParams?.page_size
        );
        state.miniLocationList = list;
        state.miniLocationLoading = false;
        state.miniLocationParams = {
          ...state.miniLocationParams,
          no_of_pages: noofpages,
        };
      })
      .addCase(getMiniLocation.rejected, (state, action) => {
        state.status = "getMiniLocation failed";
        state.miniLocationLoading = false;
        state.error = action.error.message;
      })
  }
});

// Action creators are generated for each case reducer function
export const {
  setMiniStatesParams,
  setMiniZoneParams,
  setMiniAreaParams,
  setMiniLocationParams,
  getMiniStateClearData,
  setMiniCityParams,
  setMiniUserParams,
} = miniSlice.actions;

export default miniSlice.reducer;
