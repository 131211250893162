import { createSlice } from "@reduxjs/toolkit";
import { VehicleOutwardAdd, VehicleOutwardEdit, getVehicleOutwardByID, getVehicleOutwardList,VehicleOutwardDelete } from "./vehicleOutwardAction";

const vehicleOutwardSlice = createSlice({
    name: "vehicleoutward",
    initialState: {
        error: "Sorry! No Data Found.",
        message: null,
        loading: false,
        unitsLoading: false,
        model: false,
        error_msg: null,
        rowdata: {},
        // autoFillItems: {},
        filterParamsData: {},
        listCount: 0,
        vehicleOutwardlist: [],
        vehicleOutwardItems: [],
        vehicleOutwardParams: {
            no_of_pages: 0,
            page_size: 10,
            page: 1,
            search: "",
            currentSort: "code",
            sortOrder: "-",
        },
        listItemCount: 0,
        pageParams: {
            start_date: "",
            end_date: "",
            no_of_pages: 0,
            page_size: 10,
            page: 1,
            search: "",
        },
        uploadImage: {
            file: {},
            imageUrl: "",
            loading: false,
        },

    },
    reducers: {

        InputChangeValue: (state, action) => {
            return {
                ...state,
                rowdata: {
                    ...state.rowdata,
                    [action.payload.key]: action.payload.value,
                },
            };
        },
        SetVehicleOutwardParams: (state, action) => {
            return {
                ...state,
                vehicleOutwardParams: {
                    ...state.vehicleOutwardParams,
                    ...action.payload
                },
            };
        },
        isModelVisible: (state, action) => {
            if (!action.payload) {
                return {
                    ...state,
                    model: action.payload,
                    rowdata: {},
                };
            } else {
                return {
                    ...state,
                    model: action.payload,
                };
            }
        },
        isDrawerVisible: (state, action) => {
            return {
                ...state,
                drawer: action.payload,
            };
        },
        setAutoFillItems: (state, action) => {
            return {
                ...state,
                ...action.payload,
            }
        },
        changeParams: (state, action) => {
            return {
                ...state,
                pageParams: action.payload,
            };
        },
        setVehicleOutwardItemsList: (state, action) => {
            return {
                ...state,
                vehicleOutwardItems: [...state.vehicleOutwardItems, action.payload]
            }
        },
        clearuploadImage: (state, action) => {
            return {
                ...state,
                uploadImage: {
                    file: {},
                    imageUrl: "",
                    loading: false,
                },
            };
        },
        setUploadImage: (state, action) => {
            return {
                ...state,
                uploadImage: {
                    file: action.payload.file,
                    imageUrl: action.payload.imageUrl,
                    loading: action.payload.loading
                },
            };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getVehicleOutwardList.pending, (state, action) => {
                state.status = "getVehicleOutwardList loading";
                state.loading = true;
            })
            .addCase(getVehicleOutwardList.fulfilled, (state, action) => {
                state.status = "getVehicleOutwardList succeeded";
                state.loading = false;
                var response = action.payload.response;
                var noofpages = Math.ceil(
                    action.payload.count / state.vehicleOutwardParams?.page_size
                );

                state.vehicleOutwardlist = response.results;
                state.listCount = response.count;
                state.vehicleOutwardParams = {
                    ...state.vehicleOutwardParams,
                    no_of_pages: noofpages,
                };
                state.vehicleOutwardItems = [];
            })
            .addCase(getVehicleOutwardList.rejected, (state, action) => {
                state.status = "getVehicleOutwardList failed";
                state.loading = false;
                state.error = action.error.message;
            })

            .addCase(getVehicleOutwardByID.pending, (state, action) => {
                state.status = "getVehicleOutwardByID loading";
                state.loading = true;
            })
            .addCase(getVehicleOutwardByID.fulfilled, (state, action) => {
                state.status = "getVehicleOutwardByID succeeded";
                state.loading = false
                state.rowdata = action.payload;
                state.listItemCount = state.rowdata.vehicleoutwarditems?.length;
            }
            )
            .addCase(getVehicleOutwardByID.rejected, (state, action) => {
                state.status = "getVehicleOutwardByID failed";
                state.loading = false;
                state.error = action.error.message;

            })
            .addCase(VehicleOutwardAdd.pending, (state, action) => {
                state.status = "VehicleOutwardAdd loading";
                state.loading = true;
            })
            .addCase(VehicleOutwardAdd.fulfilled, (state, action) => {
                state.status = "VehicleOutwardAdd succeeded";
                state.loading = false
                state.model = action.payload.modal;
                state.message = action.payload.response;
                state.rowdata = {};
                state.vehicleOutwardItems = [];
            }
            )
            .addCase(VehicleOutwardAdd.rejected, (state, action) => {
                state.status = "VehicleOutwardAdd failed";
                state.loading = false;
                state.error = action.error.message;
                console.log("action.error.message", action.error.message);
            })
            .addCase(VehicleOutwardEdit.pending, (state, action) => {
                state.status = "VehicleOutwardEdit loading";
                state.loading = true;
            })
            .addCase(VehicleOutwardEdit.fulfilled, (state, action) => {
                console.log(action.payload.response)
                state.status = "VehicleOutwardEdit succeeded";
                state.loading = false;
                state.active = action.payload.active;
                state.vehicleOutwardItems = [];
            })
            .addCase(VehicleOutwardEdit.rejected, (state, action) => {
                state.status = "VehicleOutwardEdit failed";
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(VehicleOutwardDelete.pending, (state, action) => {
                state.status = "VehicleOutwardDelete loading";
                state.loading = true;
            })
            .addCase(VehicleOutwardDelete.fulfilled, (state, action) => {
                state.status = "VehicleOutwardDelete succeeded";
                state.loading = false
            }
            )
            .addCase(VehicleOutwardDelete.rejected, (state, action) => {
                state.status = "VehicleOutwardDelete failed";
                state.loading = false;
                state.error = action.error.message;
            })
    }

});
export const {
    InputChangeValue,
    SetVehicleOutwardParams,
    isModelVisible,
    isDrawerVisible,
    setAutoFillItems,
    changeParams,
    setVehicleOutwardItemsList,
    clearuploadImage,
    setUploadImage
} = vehicleOutwardSlice.actions;

export default vehicleOutwardSlice.reducer;