import { createSlice } from "@reduxjs/toolkit";
import { getInternalTransferOutByID, getInternalTransferOutList, internalTransferOutAdd, internalTransferOutEdit,internalTransferOutDelete } from './internalTransferOutAction';

const internalTransferOutSlice = createSlice({
    name: "internaltransferout",
    initialState: {
        error: "Sorry! No Data Found.",
        message: null,
        loading: false,
        unitsLoading: false,
        model: false,
        error_msg: null,
        rowdata: {},
        autoFillItems: {},
        filterParamsData: {},
        listCount: 0,
        internalTransferOutlist: [],
        internaltransferoutitems: [],
        internalTransferOutParams: {
            no_of_pages: 0,
            page_size: 10,
            page: 1,
            search: "",
            currentSort: "code",
            sortOrder: "-",
        },
        listItemCount: 0,
        pageParams: {
            start_date: "",
            end_date: "",
            no_of_pages: 0,
            page_size: 10,
            page: 1,
            search: "",
        },
    },
    reducers: {
        InputChangeValue: (state, action) => {
            return {
                ...state,
                rowdata: {
                    ...state.rowdata,
                    [action.payload.key]: action.payload.value,
                },
            };
        },
        SetInternalTransferOutParams: (state, action) => {
            return {
                ...state,
                internalTransferOutParams: {
                    ...state.internalTransferOutParams,
                    ...action.payload
                },
            };
        },
        SetInternalTransferOutItemParams: (state, action) => {
            return {
                ...state,
                pageParams: {
                    ...state.pageParams,
                    ...action.payload
                },
            };
        },
        isModelVisible: (state, action) => {
            if (!action.payload) {
                return {
                    ...state,
                    model: action.payload,
                    rowdata: {},
                };
            } else {
                return {
                    ...state,
                    model: action.payload,
                };
            }
        },
        isDrawerVisible: (state, action) => {
            return {
                ...state,
                drawer: action.payload,
            };
        },
        setItemsList: (state, action) => {
            return {
                ...state,
                internaltransferoutitems: [...state.internaltransferoutitems, action.payload]
            }
        },
        // changeParams: (state, action) => {
        // 	return {
        // 		...state,
        // 		pageParams: action.payload,
        // 	};
        // },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getInternalTransferOutList.pending, (state, action) => {
                state.status = " loading";
                state.loading = true;
            })
            .addCase(getInternalTransferOutList.fulfilled, (state, action) => {
                state.status = "getInternalTransferOutList succeeded";
                state.loading = false;
                var response = action.payload.response;
                var noofpages = Math.ceil(
                    action.payload.count / state.vehicleInwardParams?.page_size
                );

                state.internalTransferOutlist = response.results;
                state.listCount = response.count;
                state.internalTransferOutParams = {
                    ...state.internalTransferOutParams,
                    no_of_pages: noofpages,
                };                
            })
            .addCase(getInternalTransferOutList.rejected, (state, action) => {
                state.status = "getInternalTransferOutList failed";
                state.loading = false;
                state.error = action.error.message;
            })

            .addCase(getInternalTransferOutByID.pending, (state, action) => {
                state.status = "getInternalTransferOutByID loading";
                state.loading = true;
            })
            .addCase(getInternalTransferOutByID.fulfilled, (state, action) => {
                state.status = "getInternalTransferOutByID succeeded";
                state.loading = false
                state.rowdata = action.payload;
                state.listItemCount = action.payload.internaltransferoutitems.length;
            }
            )
            .addCase(getInternalTransferOutByID.rejected, (state, action) => {
                state.status = "getInternalTransferOutByID failed";
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(internalTransferOutAdd.pending, (state, action) => {
                state.status = "internalTransferOutAdd loading";
                state.loading = true;
            })
            .addCase(internalTransferOutAdd.fulfilled, (state, action) => {
                state.status = "internalTransferOutAdd succeeded";
                state.loading = false;
                state.model = action.payload.modal;
                state.message = action.payload.response;
                state.rowdata = {};
                state.internaltransferoutitems = [];
            }
            )
            .addCase(internalTransferOutAdd.rejected, (state, action) => {
                state.status = "internalTransferOutAdd failed";
                state.loading = false;
                state.error = action.error.message;
                console.log("action.error.message", action.error.message);
            })
            .addCase(internalTransferOutEdit.pending, (state, action) => {
                state.status = "internalTransferOutEdit loading";
                state.loading = true;
            })
            .addCase(internalTransferOutEdit.fulfilled, (state, action) => {
                console.log(action.payload.response)
                state.status = "internalTransferOutEdit succeeded";
                state.loading = false;
                state.active = action.payload.active;
            })
            .addCase(internalTransferOutEdit.rejected, (state, action) => {
                state.status = "internalTransferOutEdit failed";
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(internalTransferOutDelete.pending, (state, action) => {
                state.status = "internalTransferOutDelete loading";
                state.loading = true;
            })
            .addCase(internalTransferOutDelete.fulfilled, (state, action) => {
                state.status = "internalTransferOutDelete succeeded";
                state.loading = false
            }
            )
            .addCase(internalTransferOutDelete.rejected, (state, action) => {
                state.status = "internalTransferOutDelete failed";
                state.loading = false;
                state.error = action.error.message;
            })
    }

});
export const {
    InputChangeValue,
    SetInternalTransferOutParams,
    isModelVisible,
    isDrawerVisible,
    setItemsList,
    SetInternalTransferOutItemParams
} = internalTransferOutSlice.actions;

export default internalTransferOutSlice.reducer;