import { createSlice } from "@reduxjs/toolkit";
import { getLotList, getLotByID, addLot, getlotByCompLocWarhouse } from "./lotAction";

const lotSlice = createSlice({
    name: "lot",
    initialState: {
        error: "Sorry! No Data Found.",
        message: null,
        loading: false,
        unitsLoading: false,
        model: false,
        error_msg: null,
        rowdata: {},
        listCount: 0,
        lotlist: [],
        locWarehouseList: [],
        lotParams: {
            no_of_pages: 0,
            page_size: 10,
            page: 1,
            search: "",
            currentSort: "code",
            sortOrder: "-",
        },
        lotByCompLocWarehouse: []
    },
    reducers: {

        InputChangeValue: (state, action) => {
            return {
                ...state,
                rowdata: {
                    ...state.rowdata,
                    [action.payload.key]: action.payload.value,
                },
            };
        },
        SetLotParams: (state, action) => {
            return {
                ...state,
                lotParams: {
                    ...state.lotParams,
                    ...action.payload
                },
            };
        },
        setLocWarehouseList: (state, action) => {
            return {
                ...state,
                locWarehouseList: action.payload,
            };
        },
        isModelVisible: (state, action) => {
            if (!action.payload) {
                return {
                    ...state,
                    model: action.payload,
                    rowdata: {},
                };
            } else {
                return {
                    ...state,
                    model: action.payload,
                };
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getLotList.pending, (state, action) => {
                state.status = "getLotList loading";
                state.loading = true;
            })
            .addCase(getLotList.fulfilled, (state, action) => {
                state.status = "getLotList succeeded";
                state.loading = false;
                var response = action.payload.response;
                var noofpages = Math.ceil(
                    action.payload.count / state.lotParams?.page_size
                );

                state.lotlist = response.results;
                state.listCount = response.count;
                state.lotParams = {
                    ...state.lotParams,
                    no_of_pages: noofpages,
                };
            })
            .addCase(getLotList.rejected, (state, action) => {
                state.status = "getLotList failed";
                state.loading = false;
                state.error = action.error.message;
            })

            .addCase(getLotByID.pending, (state, action) => {
                state.status = "getLotByID loading";
                state.loading = true;
            })
            .addCase(getLotByID.fulfilled, (state, action) => {
                state.status = "getLotByID succeeded";
                state.loading = false
                state.rowdata = action.payload;
            }
            )
            .addCase(getLotByID.rejected, (state, action) => {
                state.status = "getLotByID failed";
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(addLot.pending, (state, action) => {
                state.status = "addLot loading";
                state.loading = true;
            })
            .addCase(addLot.fulfilled, (state, action) => {
                state.status = "addLot succeeded";
                state.loading = false
                state.model = action.payload.modal;
                state.message = action.payload.response;
                state.rowdata = {};
            }
            )
            .addCase(addLot.rejected, (state, action) => {
                state.status = "addLot failed";
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(getlotByCompLocWarhouse.pending, (state, action) => {
                state.status = "getlotByCompLocWarhouse loading";
                state.loading = true;
            })
            .addCase(getlotByCompLocWarhouse.fulfilled, (state, action) => {
                state.status = "getlotByCompLocWarhouse succeeded";
                state.loading = false
                state.lotByCompLocWarehouse = action.payload.results;
            }
            )
            .addCase(getlotByCompLocWarhouse.rejected, (state, action) => {
                state.status = "getlotByCompLocWarhouse failed";
                state.loading = false;
                state.error = action.error.message;
            })
    }

});
export const {
    InputChangeValue,
    SetLotParams,
    isModelVisible,
    setLocWarehouseList,
} = lotSlice.actions;

export default lotSlice.reducer;