import { createSlice } from "@reduxjs/toolkit";
import {  StockAdjustmentMinusAdd, StockAdjustmentMinusEdit,getStockAdjustmentMinusByID,getStockAdjustmentMinusList, StockAdjustmentMinusDelete } from "./stockAdjustmentMinusAction";

const stockAdjustmentMinusSlice = createSlice({
    name: "stockadjustmentminus",
    initialState: {
        error: "Sorry! No Data Found.",
        message: null,
        loading: false,
        unitsLoading: false,
        model: false,
        error_msg: null,
        rowdata: {},
        // autoFillItems: {},
        filterParamsData: {},
        listCount: 0,
        stockAdjustmentMinuslist: [],
        stockAdjustmentMinusItems: [],
        stockAdjustmentMinusParams: {
            no_of_pages: 0,
            page_size: 10,
            page: 1,
            search: "",
            currentSort: "code",
            sortOrder: "-",
        },
        listItemCount:0,
        pageParams: {
			start_date: "",
			end_date: "",
			no_of_pages: 0,
			page_size: 10,
			page: 1,
			search: "",
		},

    },
    reducers: {

        InputChangeValue: (state, action) => {
            return {
                ...state,
                rowdata: {
                    ...state.rowdata,
                    [action.payload.key]: action.payload.value,
                },
            };
        },
        SetStockAdjustmentMinusParams: (state, action) => {
            return {
                ...state,
                stockAdjustmentMinusParams: {
                    ...state.stockAdjustmentMinusParams,
                    ...action.payload
                },
            };
        },
        isModelVisible: (state, action) => {
            if (!action.payload) {
                return {
                    ...state,
                    model: action.payload,
                    rowdata: {},
                };
            } else {
                return {
                    ...state,
                    model: action.payload,
                };
            }
        },
        isDrawerVisible: (state, action) => {
            return {
                ...state,
                drawer: action.payload,
            };
        },
        setAutoFillItems: (state, action) => {
            return {
                ...state,
                ...action.payload,
            }
        },
        changeParams: (state, action) => {
			return {
				...state,
				pageParams: action.payload,
			};
		},
        setStockAdjustmentItemsList: (state, action) => {
            return {
                ...state,
                stockAdjustmentMinusItems: [...state.stockAdjustmentMinusItems, action.payload]
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getStockAdjustmentMinusList.pending, (state, action) => {
                state.status = "getStockAdjustmentMinusList loading";
                state.loading = true;
            })
            .addCase(getStockAdjustmentMinusList.fulfilled, (state, action) => {
                state.status = "getStockAdjustmentMinusList succeeded";
                state.loading = false;
                var response = action.payload.response;
                var noofpages = Math.ceil(
                    action.payload.count / state.stockAdjustmentMinusParams?.page_size
                );

                state.stockAdjustmentMinuslist = response.results;
                state.listCount = response.count;
                state.stockAdjustmentMinusParams = {
                    ...state.stockAdjustmentMinusParams,
                    no_of_pages: noofpages,
                };
                state.rowdata.trucksheet_items = [];
                state.stockAdjustmentMinusItems = [];
            })
            .addCase(getStockAdjustmentMinusList.rejected, (state, action) => {
                state.status = "getStockAdjustmentMinusList failed";
                state.loading = false;
                state.error = action.error.message;
            })

            .addCase(getStockAdjustmentMinusByID.pending, (state, action) => {
                state.status = "getStockAdjustmentMinusByID loading";
                state.loading = true;
            })
            .addCase(getStockAdjustmentMinusByID.fulfilled, (state, action) => {
                state.status = "getStockAdjustmentMinusByID succeeded";
                state.loading = false
                state.rowdata = action.payload;
                state.listItemCount = action.payload.stockadjustmentminusitems?.length;
            }
            )
            .addCase(getStockAdjustmentMinusByID.rejected, (state, action) => {
                state.status = "getStockAdjustmentMinusByID failed";
                state.loading = false;
                state.error = action.error.message;

            })
            .addCase(StockAdjustmentMinusAdd.pending, (state, action) => {
                state.status = "StockAdjustmentMinusAdd loading";
                state.loading = true;
            })
            .addCase(StockAdjustmentMinusAdd.fulfilled, (state, action) => {
                state.status = "StockAdjustmentMinusAdd succeeded";
                state.loading = false
                state.model = action.payload.modal;
                state.message = action.payload.response;
                state.rowdata = {};
                state.stockAdjustmentMinusItems =[];
            }
            )
            .addCase(StockAdjustmentMinusAdd.rejected, (state, action) => {
                state.status = "StockAdjustmentMinusAdd failed";
                state.loading = false;
                state.error = action.error.message;
                console.log("action.error.message", action.error.message);
            })
            .addCase(StockAdjustmentMinusEdit.pending, (state, action) => {
                state.status = "StockAdjustmentMinusEdit loading";
                state.loading = true;
            })
            .addCase(StockAdjustmentMinusEdit.fulfilled, (state, action) => {
                console.log(action.payload.response)
                state.status = "StockAdjustmentMinusEdit succeeded";
                state.loading = false;
                state.active = action.payload.active;
                state.stockAdjustmentMinusItems =[];
            })
            .addCase(StockAdjustmentMinusEdit.rejected, (state, action) => {
                state.status = "StockAdjustmentMinusEdit failed";
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(StockAdjustmentMinusDelete.pending, (state, action) => {
                state.status = "StockAdjustmentMinusDelete loading";
                state.loading = true;
            })
            .addCase(StockAdjustmentMinusDelete.fulfilled, (state, action) => {
                state.status = "StockAdjustmentMinusDelete succeeded";
                state.loading = false
            }
            )
            .addCase(StockAdjustmentMinusDelete.rejected, (state, action) => {
                state.status = "StockAdjustmentMinusDelete failed";
                state.loading = false;
                state.error = action.error.message;
            })

    }

});
export const {
    InputChangeValue,
    SetStockAdjustmentMinusParams,
    isModelVisible,
    isDrawerVisible,
    setAutoFillItems,
    changeParams,
    setStockAdjustmentItemsList,
} = stockAdjustmentMinusSlice.actions;

export default stockAdjustmentMinusSlice.reducer;