import { createSlice } from "@reduxjs/toolkit";
import { getWarehouseByID, getWarehouseList, getWarehouseByLocation} from "./warehouseAction";

const warehouseSlice = createSlice({
    name: "warehouse",
    initialState: {
        error: "Sorry! No Data Found.",
        message: null,
        loading: false,        
        unitsLoading: false,
        model: false,
        error_msg: null,
        rowdata: {},
        listCount: 0,
        warehouselist: [],
        warehouseParams: {
            no_of_pages: 0,
            page_size: 10,
            page: 1,
            search: "",
            currentSort: "code",
            sortOrder: "-",
        },
        locationsByWarehouse: []
        
    },
    reducers: {
       
        InputChangeValue: (state, action) => {
            return {
                ...state,
                rowdata: {
                    ...state.rowdata,
                    [action.payload.key]: action.payload.value,
                },
            };
        },   
        SetWarehouseParams: (state, action) => {
            return {
                ...state,
                warehouseParams: {
                    ...state.warehouseParams,
                    ...action.payload
                },
            };
        },
        isModelVisible: (state, action) => {
            if (!action.payload) {
                return {
                    ...state,
                    model: action.payload,
                    rowdata: {},
                };
            } else {
                return {
                    ...state,
                    model: action.payload,
                };
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getWarehouseList.pending, (state, action) => {
                state.status = "getWarehouseList loading";
                state.loading = true;
            })
            .addCase(getWarehouseList.fulfilled, (state, action) => {
                state.status = "getWarehouseList succeeded";
                state.loading = false;
                var response = action.payload.response;
                var noofpages = Math.ceil(
                    action.payload.count / state.warehouseParams?.page_size
                );

                state.warehouselist = response.results;
                state.listCount = response.count;
                state.warehouseParams = {
                    ...state.warehouseParams,
                    no_of_pages: noofpages,
                };
            })
            .addCase(getWarehouseList.rejected, (state, action) => {
                state.status = "getWarehouseList failed";
                state.loading = false;
                state.error = action.error.message;
            })
            
            .addCase(getWarehouseByID.pending, (state, action) => {
                state.status = "getWarehouseByID loading";
                state.loading = true;
            })
            .addCase(getWarehouseByID.fulfilled, (state, action) => {
                state.status = "getWarehouseByID succeeded";
                state.loading = false
                state.rowdata = action.payload;
            }
            )
            .addCase(getWarehouseByID.rejected, (state, action) => {
                state.status = "getWarehouseByID failed";
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(getWarehouseByLocation.pending, (state, action) => {
                state.status = "getWarehouseByLocation loading";
                state.loading = true;
            })
            .addCase(getWarehouseByLocation.fulfilled, (state, action) => {
                state.status = "getWarehouseByLocation succeeded";
                state.loading = false
                state.locationsByWarehouse = action.payload.results;
            }
            )
            .addCase(getWarehouseByLocation.rejected, (state, action) => {
                state.status = "getWarehouseByLocation failed";
                state.loading = false;
                state.error = action.error.message;
            })
           
    }

});
export const {
    InputChangeValue,
    SetWarehouseParams,
    isModelVisible
} = warehouseSlice.actions;

export default warehouseSlice.reducer;