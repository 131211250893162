import { createSlice } from "@reduxjs/toolkit";
import { ProductionInAdd, ProductionInEdit, getProductionInByID, getProductionInList, ProductionInDelete } from "./productionInAction";

const productionInSlice = createSlice({
    name: "productionin",
    initialState: {
        error: "Sorry! No Data Found.",
        message: null,
        loading: false,
        unitsLoading: false,
        model: false,
        error_msg: null,
        rowdata: {},
        // autoFillItems: {},
        filterParamsData: {},
        listCount: 0,
        productionInlist: [],
        productionInItems: [],
        productionInParams: {
            no_of_pages: 0,
            page_size: 10,
            page: 1,
            search: "",
            currentSort: "code",
            sortOrder: "-",
        },
        listItemCount: 0,
        pageParams: {
            start_date: "",
            end_date: "",
            no_of_pages: 0,
            page_size: 10,
            page: 1,
            search: "",
        },
        uploadImage: {
            file: {},
            imageUrl: "",
            loading: false,
        },

    },
    reducers: {

        InputChangeValue: (state, action) => {
            return {
                ...state,
                rowdata: {
                    ...state.rowdata,
                    [action.payload.key]: action.payload.value,
                },
            };
        },
        SetProductionInParams: (state, action) => {
            return {
                ...state,
                productionInParams: {
                    ...state.productionInParams,
                    ...action.payload
                },
            };
        },
        isModelVisible: (state, action) => {
            if (!action.payload) {
                return {
                    ...state,
                    model: action.payload,
                    rowdata: {},
                };
            } else {
                return {
                    ...state,
                    model: action.payload,
                };
            }
        },
        isDrawerVisible: (state, action) => {
            return {
                ...state,
                drawer: action.payload,
            };
        },
        setAutoFillItems: (state, action) => {
            return {
                ...state,
                ...action.payload,
            }
        },
        changeParams: (state, action) => {
            return {
                ...state,
                pageParams: action.payload,
            };
        },
        setProductionInItemsList: (state, action) => {
            return {
                ...state,
                productionInItems: [...state.productionInItems, action.payload]
            }
        },
        clearuploadImage: (state, action) => {
            return {
                ...state,
                uploadImage: {
                    file: {},
                    imageUrl: "",
                    loading: false,
                },
            };
        },
        setUploadImage: (state, action) => {
            return {
                ...state,
                uploadImage: {
                    file: action.payload.file,
                    imageUrl: action.payload.imageUrl,
                    loading: action.payload.loading
                },
            };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getProductionInList.pending, (state, action) => {
                state.status = "getProductionInList loading";
                state.loading = true;
            })
            .addCase(getProductionInList.fulfilled, (state, action) => {
                state.status = "getProductionInList succeeded";
                state.loading = false;
                var response = action.payload.response;
                var noofpages = Math.ceil(
                    action.payload.count / state.productionInParams?.page_size
                );

                state.productionInlist = response.results;
                state.listCount = response.count;
                state.productionInParams = {
                    ...state.productionInParams,
                    no_of_pages: noofpages,
                };
                state.productionInItems = [];
            })
            .addCase(getProductionInList.rejected, (state, action) => {
                state.status = "getProductionInList failed";
                state.loading = false;
                state.error = action.error.message;
            })

            .addCase(getProductionInByID.pending, (state, action) => {
                state.status = "getProductionInByID loading";
                state.loading = true;
            })
            .addCase(getProductionInByID.fulfilled, (state, action) => {
                state.status = "getProductionInByID succeeded";
                state.loading = false
                state.rowdata = action.payload;
                state.listItemCount = action.payload.productioninitems?.length;
            }
            )
            .addCase(getProductionInByID.rejected, (state, action) => {
                state.status = "getProductionInByID failed";
                state.loading = false;
                state.error = action.error.message;

            })
            .addCase(ProductionInAdd.pending, (state, action) => {
                state.status = "ProductionInAdd loading";
                state.loading = true;
            })
            .addCase(ProductionInAdd.fulfilled, (state, action) => {
                state.status = "ProductionInAdd succeeded";
                state.loading = false
                state.model = action.payload.modal;
                state.message = action.payload.response;
                state.rowdata = {};
                state.productionInItems = [];
            }
            )
            .addCase(ProductionInAdd.rejected, (state, action) => {
                state.status = "ProductionInAdd failed";
                state.loading = false;
                state.error = action.error.message;
                console.log("action.error.message", action.error.message);
            })
            .addCase(ProductionInEdit.pending, (state, action) => {
                state.status = "ProductionInEdit loading";
                state.loading = true;
            })
            .addCase(ProductionInEdit.fulfilled, (state, action) => {
                console.log(action.payload.response)
                state.status = "ProductionInEdit succeeded";
                state.loading = false;
                state.active = action.payload.active;
                state.productionInItems = [];
            })
            .addCase(ProductionInEdit.rejected, (state, action) => {
                state.status = "ProductionInEdit failed";
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(ProductionInDelete.pending, (state, action) => {
                state.status = "ProductionInDelete loading";
                state.loading = true;
            })
            .addCase(ProductionInDelete.fulfilled, (state, action) => {
                state.status = "ProductionInDelete succeeded";
                state.loading = false
            }
            )
            .addCase(ProductionInDelete.rejected, (state, action) => {
                state.status = "ProductionInDelete failed";
                state.loading = false;
                state.error = action.error.message;
            })
    }

});
export const {
    InputChangeValue,
    SetProductionInParams,
    isModelVisible,
    isDrawerVisible,
    setAutoFillItems,
    changeParams,
    setProductionInItemsList,
    clearuploadImage,
    setUploadImage
} = productionInSlice.actions;

export default productionInSlice.reducer;