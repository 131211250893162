import { createSlice } from "@reduxjs/toolkit";
import { ProductionOutAdd, ProductionOutEdit, getProductionOutByID, getProductionOutList,ProductionOutDelete } from "./productionOutAction";

const productionOutSlice = createSlice({
    name: "productionout",
    initialState: {
        error: "Sorry! No Data Found.",
        message: null,
        loading: false,
        unitsLoading: false,
        model: false,
        error_msg: null,
        rowdata: {},
        // autoFillItems: {},
        filterParamsData: {},
        listCount: 0,
        productionOutlist: [],
        productionOutItems: [],
        productionOutParams: {
            no_of_pages: 0,
            page_size: 10,
            page: 1,
            search: "",
            currentSort: "code",
            sortOrder: "-",
        },
        listItemCount: 0,
        pageParams: {
            start_date: "",
            end_date: "",
            no_of_pages: 0,
            page_size: 10,
            page: 1,
            search: "",
        },
        uploadImage: {
            file: {},
            imageUrl: "",
            loading: false,
        },

    },
    reducers: {

        InputChangeValue: (state, action) => {
            return {
                ...state,
                rowdata: {
                    ...state.rowdata,
                    [action.payload.key]: action.payload.value,
                },
            };
        },
        SetProductionOutParams: (state, action) => {
            return {
                ...state,
                productionOutParams: {
                    ...state.productionOutParams,
                    ...action.payload
                },
            };
        },
        isModelVisible: (state, action) => {
            if (!action.payload) {
                return {
                    ...state,
                    model: action.payload,
                    rowdata: {},
                };
            } else {
                return {
                    ...state,
                    model: action.payload,
                };
            }
        },
        isDrawerVisible: (state, action) => {
            return {
                ...state,
                drawer: action.payload,
            };
        },
        setAutoFillItems: (state, action) => {
            return {
                ...state,
                ...action.payload,
            }
        },
        changeParams: (state, action) => {
            return {
                ...state,
                pageParams: action.payload,
            };
        },
        setProductionOutItemsList: (state, action) => {
            return {
                ...state,
                productionOutItems: [...state.productionOutItems, action.payload]
            }
        },
        clearuploadImage: (state, action) => {
            return {
                ...state,
                uploadImage: {
                    file: {},
                    imageUrl: "",
                    loading: false,
                },
            };
        },
        setUploadImage: (state, action) => {
            return {
                ...state,
                uploadImage: {
                    file: action.payload.file,
                    imageUrl: action.payload.imageUrl,
                    loading: action.payload.loading
                },
            };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getProductionOutList.pending, (state, action) => {
                state.status = "getProductionOutList loading";
                state.loading = true;
            })
            .addCase(getProductionOutList.fulfilled, (state, action) => {
                state.status = "getProductionOutList succeeded";
                state.loading = false;
                var response = action.payload.response;
                var noofpages = Math.ceil(
                    action.payload.count / state.productionOutParams?.page_size
                );

                state.productionOutlist = response.results;
                state.listCount = response.count;
                state.productionOutParams = {
                    ...state.productionOutParams,
                    no_of_pages: noofpages,
                };
                state.productionOutItems = [];
            })
            .addCase(getProductionOutList.rejected, (state, action) => {
                state.status = "getProductionOutList failed";
                state.loading = false;
                state.error = action.error.message;
            })

            .addCase(getProductionOutByID.pending, (state, action) => {
                state.status = "getProductionOutByID loading";
                state.loading = true;
            })
            .addCase(getProductionOutByID.fulfilled, (state, action) => {
                state.status = "getProductionOutByID succeeded";
                state.loading = false
                state.rowdata = action.payload;
                state.listItemCount = action.payload.productionoutitems?.length;
            }
            )
            .addCase(getProductionOutByID.rejected, (state, action) => {
                state.status = "getProductionOutByID failed";
                state.loading = false;
                state.error = action.error.message;

            })
            .addCase(ProductionOutAdd.pending, (state, action) => {
                state.status = "ProductionOutAdd loading";
                state.loading = true;
            })
            .addCase(ProductionOutAdd.fulfilled, (state, action) => {
                state.status = "ProductionOutAdd succeeded";
                state.loading = false
                state.model = action.payload.modal;
                state.message = action.payload.response;
                state.rowdata = {};
                state.productionOutItems = [];
            }
            )
            .addCase(ProductionOutAdd.rejected, (state, action) => {
                state.status = "ProductionOutAdd failed";
                state.loading = false;
                state.error = action.error.message;
                console.log("action.error.message", action.error.message);
            })
            .addCase(ProductionOutEdit.pending, (state, action) => {
                state.status = "ProductionOutEdit loading";
                state.loading = true;
            })
            .addCase(ProductionOutEdit.fulfilled, (state, action) => {
                console.log(action.payload.response)
                state.status = "ProductionOutEdit succeeded";
                state.loading = false;
                state.active = action.payload.active;
                state.productionOutItems = [];
            })
            .addCase(ProductionOutEdit.rejected, (state, action) => {
                state.status = "ProductionOutEdit failed";
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(ProductionOutDelete.pending, (state, action) => {
                state.status = "ProductionOutDelete loading";
                state.loading = true;
            })
            .addCase(ProductionOutDelete.fulfilled, (state, action) => {
                state.status = "ProductionOutDelete succeeded";
                state.loading = false
            }
            )
            .addCase(ProductionOutDelete.rejected, (state, action) => {
                state.status = "ProductionOutDelete failed";
                state.loading = false;
                state.error = action.error.message;
            })
    }

});
export const {
    InputChangeValue,
    SetProductionOutParams,
    isModelVisible,
    isDrawerVisible,
    setAutoFillItems,
    changeParams,
    setProductionOutItemsList,
    clearuploadImage,
    setUploadImage
} = productionOutSlice.actions;

export default productionOutSlice.reducer;