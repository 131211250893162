import { createAsyncThunk } from "@reduxjs/toolkit";
import {
    postAdd,
    getParamsList,
    postEdit,
    postDelete,
    getList,
    patchImg,
    putImg,
    postFormData,
    addParams,
} from "../../../helpers/Helper";
import {
    RiCheckboxCircleLine,
    RiAlertLine,
    RiInformationLine,
} from "react-icons/ri";
import { serialize } from "object-to-formdata";

export const getWarehouseList = createAsyncThunk(
    "/getWarehouseList",
    async (payload) => {
        var data = payload;
        var params = {};

        for (const k in data) {
            if (Object.hasOwnProperty.call(data, k)) {
                if (k === "currentSort" || k === "sortOrder") {
                    params.ordering = data.sortOrder + data.currentSort;
                } else {
                    if (data[k] != "" && k != null && k != "no_of_pages") {
                        params[k] = data[k];
                    }
                }
            }
        }
        try {
            const response = await getParamsList("/masters/warehouse/", params);
            if (response) {
                return { response };
            } else {
                throw new Error(response);
            }
        } catch (error) {
            throw error.message;
        }
    }
);
export const getWarehouseByID = createAsyncThunk(
    "/getWarehouseByID",
    async (payload) => {
        try {
            const response = await getList("/masters/warehouse/" + payload + "/");
            return response.data;
        } catch (error) {
            throw error.message;
        }
    }
);
export const getWarehouseByLocation = createAsyncThunk(
    "/getWarehouseByLocation",
    async (payload) => {
        try {
            const response = await getList(`/masters/warehouse/?company=${payload.companyId}&location=${payload.locationId}`);
            return response.data;
        } catch (error) {
            throw error.message;
        }
    }
);